import React, { FC, useEffect, useState } from 'react'

import { Button, Col, Container, ListGroup, Row, Form, Spinner } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import useStatuses from '../../hooks/admin/useStatuses'
import isAuthenticated from '../../routes/PrivateRoute'
import { numberWithCommas } from '../../utils/helpers'
import useApps from '../../hooks/app/useApps'
import useQuery from '../../hooks/location/useQuery'
import useMembers from '../../hooks/admin/useMembers'
import useEnvironment from '../../hooks/location/useEnviroment'
import { appConfig } from '../../assets/customizations/config'

import useFindMember from '../../hooks/admin/useFindMember'
import { useGlobalState } from '../../hooks/store/useGlobalState'
import { providerResources } from '../../utils/constants'

import '../../App.scss'

const Admin: FC = () => {
  const query = useQuery()

  const {
    setFhirId,
    setProviderResource,
    setProviderSearchTerms
  } = useGlobalState((state) => ({
    setFhirId: state.setFhirId,
    setProviderResource: state.setProviderResource,
    setProviderSearchTerms: state.setProviderSearchTerms
  }))

  const [status, setStatus] = useState('All')
  const [page, setPage] = useState(0)
  const [viewAllProviderFhirResource, setViewAllProviderFhirResource] = useState('Organization')
  const [searchProviderResource, setSearchProviderResource] = useState({
    resource: 'Organization',
    searchTerms: ''
  })

  const [searchTerm, setSearchTerm] = useState('')

  const history = useHistory()
  const { data: counts, isLoading: countsLoading } = useStatuses()

  const { data: apps, refetch: refetchApps } = useApps('All')
  const { isLoading, data: memberdata, isPreviousData, refetch: getMembers, isSuccess: isMembersSuccess, isRefetching, remove: removeMembers } = useMembers({
    category: 'All'
  })

  const { data: environment } = useEnvironment()

  useEffect(() => {
    if (environment) {
      reset()
    }
  }, [environment])

  const reset = () => {
    if (environment?.includeTotalInFHIRRequest) {
      getMembers()
    }

    remove()
    setViewAllProviderFhirResource('Organization')
    setProviderSearchTerms('')
  }

  useEffect(() => {
    if (status) {
      refetchApps()
    }
  }, [status])

  useEffect(() => {
    const statusQuery = query.get('status')
    setStatus(statusQuery || '')
  }, [])

  const memberDataForm = useFormik({
    initialValues: {
      memberId: ''
    },
    onSubmit: () => {
      getPatient()
    },
    enableReinitialize: true
  })

  const { refetch: getPatient, isLoading: patientLoading, status: patientStatus, data: patient, remove } = useFindMember(memberDataForm.values.memberId, 'Member')

  useEffect(() => {
    if (patientStatus === 'success') {
      setFhirId(patient.id)
      history.push('/patient')
      remove()
    }
  }, [patientStatus])

  const handleSearchProviderView = (e: any, providerView: string) => {
    e.preventDefault()

    setProviderResource(providerView === 'all' ? viewAllProviderFhirResource : searchProviderResource.resource)

    if (providerView === 'search') {
      setProviderSearchTerms(searchProviderResource.searchTerms)
    }

    history.push('/provider-list')
  }

  return (
    <main>
      <Container>
        <Row>
          <Col md={3} className='p-3 border'>
            <h3>Admin Menu</h3>
            <ListGroup as='ul' variant='flush' className='m-0 p-0'>
              <ListGroup.Item as='li' className='mb-1 p-1'>
                <Link className='font-weight-bold' to='/members' onClick={() => removeMembers()}>Find Members</Link>
                <span className='d-md-block'>
                  {
                    (isLoading || isRefetching) ? <Spinner animation="border" size="sm" /> : memberdata && `(${numberWithCommas(memberdata?.total)} Members in Roster)`
                  }
                </span>
              </ListGroup.Item>
              <ListGroup.Item as='li' className='mb-1 p-1'>
                <Link className='font-weight-bold' to='/accounts'>Member Accounts</Link>
                <span className='d-md-block'>
                  {
                    countsLoading ? <Spinner animation="border" size="sm" /> : `(${counts && numberWithCommas(counts.memberCount)} Member Accounts)`
                  }
                </span>
              </ListGroup.Item>
              {/* <ListGroup.Item as='li' className='mb-1 p-1'>
              <Link to='/apps?status=All'>Apps</Link> <span className='d-md-block text-secondary'>({apps?.length} Active Apps)</span>
              {data && data.newApps === 1 &&
                <span className='d-md-block text-primary'>({data && data.newApps} New Registration)</span>
              }
              {data && (data.newApps > 1 || data.newApps === 0) &&
                <span className='d-md-block text-primary'>({data && data.newApps} New Registrations)</span>
              }
            </ListGroup.Item> */}
              <ListGroup.Item as='li' className='mb-1 p-1'>
                <Link className='font-weight-bold' to='/developers'>Developer Content</Link>
              </ListGroup.Item>
              <ListGroup.Item as='li' className='mb-1 p-1'>
                <a className='font-weight-bold' href='/help/downloads/MemberPortalAdminGuide.pdf'>Admin Guide</a>
              </ListGroup.Item>
            </ListGroup>
          </Col>
          <Col md={9} className='p-3 border'>
            <h3 className='h2'>FHIR Data</h3>
            <div>

              <h4 className="h4 mb-3 border-bottom-1 font-weight-bold">Patient Access API</h4>

              {process.env.REACT_APP_ENVIRONMENT === 'production' &&
                <p><strong>Capability Statement:</strong> https://cchp.healthlx.com:9090/fhir/metadata</p>
              }
              <div className='border border-2 rounded mb-3 p-2'>
                <Form noValidate onSubmit={memberDataForm.handleSubmit} inline>
                  <Form.Group>
                    <Form.Label className='mr-2'>View FHIR Data</Form.Label>
                    <Form.Control
                      name='memberId'
                      type='text'
                      value={memberDataForm.values.memberId}
                      onChange={memberDataForm.handleChange}
                      onBlur={memberDataForm.handleBlur}
                      isInvalid={!!memberDataForm.errors.memberId && memberDataForm.touched.memberId}
                      placeholder='Member ID'
                      className='mr-3'
                      aria-describedby='entermemberid'
                    />
                    <Form.Control.Feedback type="invalid">
                      {memberDataForm.errors.memberId}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group>
                    <Button type='submit' className='custom' disabled={patientLoading}>
                      {
                        patientLoading ? (
                          <Spinner
                            as="span"
                            animation="border"
                            role="status"
                            aria-hidden="true"
                            size='sm'
                          />
                        ) : 'View Data'
                      }
                    </Button>
                  </Form.Group>
                </Form>
                {
                  patientStatus === 'error' && (
                    <div className='text-danger mt-3'>
                      Profile not found.
                    </div>
                  )
                }
              </div>
            </div>
            {
              appConfig.use_provider_directory_api && (
                <div>
                  <h4 className="h4 mb-3 border-bottom-1 font-weight-bold">Provider Directory API</h4>
                  {process.env.REACT_APP_ENVIRONMENT === 'production' &&
                    <p><strong>Capability Statement:</strong> https://cchp.healthlx.com:9091/fhir/metadata</p>
                  }
                  <div className='border border-2 rounded mb-3 p-2'>
                    <Form noValidate onSubmit={(e) => handleSearchProviderView(e, 'all')} inline>
                      <Form.Group>
                        <Form.Label className='mr-2'>View All</Form.Label>
                        <Form.Control
                          as='select'
                          name='viewAllProviderResource'
                          className='mr-3'
                          value={viewAllProviderFhirResource}
                          onChange={(e) => setViewAllProviderFhirResource(e.target.value)}
                        >
                          <option disabled>Select Category</option>
                          {
                            providerResources.map((resource) => <option key={resource.label} value={resource.fhirResource}>{resource.label}</option>)
                          }
                        </Form.Control>
                      </Form.Group>
                      <Form.Group>
                        <Button type='submit' className='custom'>
                          View Data
                        </Button>
                      </Form.Group>
                    </Form>
                    <Form noValidate onSubmit={(e) => handleSearchProviderView(e, 'search')} inline className='mt-3'>
                      <Form.Group>
                        <Form.Label className='mr-2'>Search By</Form.Label>
                        <Form.Control
                          as='select'
                          name='resource'
                          className='mr-2'
                          value={searchProviderResource.resource}
                          onChange={(e) => setSearchProviderResource((ps) => ({ ...ps, resource: e.target.value }))}
                        >
                          <option disabled>Select Category</option>
                          {
                            providerResources.filter((providerResource) => providerResource.filter).map((resource) => <option key={resource.label} value={resource.fhirResource}>{resource.fhirResource} {resource.filter}</option>)
                          }
                        </Form.Control>
                        <Form.Control
                          type='text'
                          name='searchTerms'
                          className='mr-3'
                          value={searchProviderResource.searchTerms}
                          onChange={(e) => setSearchProviderResource((ps) => ({ ...ps, searchTerms: e.target.value }))}
                        >
                        </Form.Control>
                      </Form.Group>
                      <Form.Group>
                        <Button type='submit' className='custom'>
                          Search
                        </Button>
                      </Form.Group>
                    </Form>
                  </div>
                </div>
              )
            }
          </Col>
        </Row>
      </Container>
    </main>
  )
}

export default isAuthenticated(Admin)
